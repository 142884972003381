@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: #ffffff;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
::-webkit-scrollbar {
  width: 2px; /* Width of the scrollbar */
}

/* Track (the area on which the thumb rests) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

/* Handle (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
  border-radius: 5px; /* Rounded corners of the thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.img_flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
